<template>
  <div v-if="contactGroupDisplay">
    &nbsp;
    <table class="table is-size-6 is-fullwidth" v-if="contactGroupDisplay.Contacts.length > 0">
      <tr>
        <th>Name</th>
        <th>Email</th>
        <th>Telephone</th>
        <th>Role</th>
      </tr>
      <tr v-for="d in contactGroupDisplay.Contacts" :key="d.Id">
        <td>{{ d.Name }}</td>
        <td>{{ d.Email }}</td>
        <td>{{ d.Telephone }}</td>
        <td>{{ d.Role }}</td>
      </tr>
    </table>
    <p v-else class="is-size-6">No Contact records created yet for this group</p>
  </div>
  <div v-else-if="isLoading">Loading...</div>
</template>

<script>
import { baseMixin } from "../../../mixins/baseMixin.js";
export default {
  name: "contactGroupTable",
  mixins: [baseMixin],
  props: {
    toUpdate: {
      type: Boolean,
      default: false
    },
    contactGroup: {
      type: Object,
      default: null
    },
    contactGroupId: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      isLoading: false,
      APIUrl: process.env.VUE_APP_APIURL,
      contactGroupDisplay: this.contactGroup
    };
  },
  watch: {
    // whenever question changes, this function will run
    contactGroupId(newId, oldId) {
      //id changed, so need ajax to load it
      this.contactGroupDisplay = null; // clean up old table
      this.loadGroupIfRequired();
    },
    contactGroup(newValue, oldValue) {
      this.contactGroupDisplay = newValue; // clean up old table
    },
    toUpdate(newValue, oldValue) {
      if (newValue) this.loadGroup();
    }
  },
  created() {
    this.loadGroupIfRequired();
    //if contactgroup was passed (or both), do nothing
  },
  methods: {
    loadGroupIfRequired() {
      //if nothing is passed
      if (
        this.contactGroupDisplay === undefined &&
        this.contactGroupId === null
      )
        throw "contactGroup Object or contactGroupId must have a value";

      //if id is passed
      if (this.contactGroupId !== null) this.loadGroup();
    },
    loadGroup() {
      var self = this;
      this.contactGroupDisplay = null;
      self.isLoading = true;
      this.axios
        .get(this.APIUrl + "/contact-group/" + this.contactGroupId)
        .then(response => {
          this.contactGroupDisplay = response.data;
          self.isLoading = false;
          this.$emit("update:toUpdate", false);
        })
        .catch(function(re) {
          self.isLoading = false;
          self.catchError(re);
        });
    }
  }
};
</script>
